<template>
  <div class="loading-screen" :class="{ 'loading-screen--active': isLoading, 'loading-screen--disabled': !isLoading}">
    <div class="loading-screen__content">
      <img class="loading-screen__logo" src="~/assets/images/loading-screen-icon.svg" height="150" width="150" alt="YACHTNEEDS logo"/>
      <p class="loading-screen__text">{{ $t('base-loading-text') }}</p>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState({
        isLoading: state => state.shared.isLoading
      })
    }
  }
</script>

<style scoped lang="scss">
  .loading-screen {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    transition: opacity .3s ease;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
  }

  .loading-screen--disabled {
    animation: load .3s linear;
  }

  .loading-screen--active {
    transform: translateY(0);
    opacity: 1;
    z-index: 1000;

    .loading-screen__logo {
      transform: translateY(0);
      opacity: 1;

    }

    .loading-screen__text {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .loading-screen__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading-screen__logo {
    width: 100%;
    max-width: 60px;
    margin-bottom: 10px;
    transition: .7s ease;
    opacity: 0;

    @media (--viewport-mobile) {
      max-width: 100px;
    }
  }

  .loading-screen__text {
    font-size: 32px;
    text-align: center;
    font-family: var(--secondary-font);
    font-weight: 600;
    letter-spacing: 0.6px;
    transition: .7s ease;
    opacity: 0;

    @media (--viewport-mobile) {
      font-size: 50px;
    }
  }

  @keyframes load {
    0% {
      z-index: 1000;
    }
    99% {
      z-index: 1000;
    }
    100% {
      z-index: -1;
    }
  }
</style>
