<template>
  <div class="layout">
    <BaseLoadingScreen/>
    <BaseHeader
      :isRibbonShown="isRibbonShown"
      :closeRibbon="closeRibbon"
      :isMobile="Mobile"
    />
    <div class="layout__wrapper" :class="{ 'layout__wrapper--ribbon': isRibbonShown }">
      <nuxt />
    </div>
    <BaseSearchMobile v-if="Mobile" />

    <div
      v-if="activeModal === 'BaseSidebar'"
      class="header-actions__sidebar"
      :class="{ 'header-actions__sidebar--active': POPUP_NAMES.BASE_SIDEBAR === activeModal }"
    >
      <BaseSidebar>
        <template v-slot:sidebar-close-button>
          <button class="sidebar__button-close" type="button" @click="closePopup">
            <svg-icon name="close" class="sidebar__close"></svg-icon>
          </button>
        </template>
      </BaseSidebar>
    </div>

    <template v-if="POPUP_NAMES.REGISTRATION_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-registration-title')"
        @close="closePopup"
        withClose="true"
      >
        <RegistrationForm/>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.CHECK_EMAIL_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-confirm-email-title')"
        @close="closePopup"
        withClose="true"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-check-email-msg-title')"
          :icoName="$t('basepopup-reset-pw-send')"
          buttonClass="button button--stroke"
          :buttonTitle="isShownChangeEmailButton ? $t('basepopup-change-email-btn-title') : null"
          @popupMessageClickOnButton="changeEmail"
        >
          <div class="popup__text">{{ $t('basepopup-check-email-msg-title') }} <b>{{ registeredEmail }}</b> {{ $t('basepopup-complete-registration') }}</div>
        </PopupMessage>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.LINK_IS_EXPIRED_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-confirm-email-title')"
        @close="closePopup"
        withClose="true"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-expired-link')"
          :icoName="$t('basepopup-reset-pw-send')"
          buttonClass="button button--stroke"
          :buttonTitle="$t('basepopup-ok-btn')"
          @popupMessageClickOnButton="closePopup"
        >
          <div class="popup__text">{{ $t('basepopup-confirm-email-sending-link') }} <b>{{ registeredEmail }}</b></div>
        </PopupMessage>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.EMAIL_CONFIRMATION_WAS_SENT === activeModal">
      <BasePopup
        :title="$t('basepopup-confirm-email-title')"
        @close="closePopup"
        withClose="true"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-email-successfully-confirmation')"
          :icoName="$t('basepopup-reset-pw-send')"
          buttonClass="button button--stroke"
          buttonTitle="Close"
          @popupMessageClickOnButton="closePopup"
        >
          <div class="popup__text">{{ $t('basepopup-email-confirmation') }} <b>{{ registeredEmail }}</b></div>
        </PopupMessage>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.WRONG_CONFIRMATION_CODE_EMAIL === activeModal">
      <BasePopup
        :title="$t('basepopup-invalid-link-title')"
        @close="closePopup"
        withClose="true"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-invalid-confirmation-link')"
          :icoName="$t('basepopup-reset-pw-send')"
          buttonClass="button button--stroke"
          :buttonTitle="$t('basepopup-close-btn-title')"
          @popupMessageClickOnButton="closePopup"
        >
          <div class="popup__text">
            {{ $t('basepopup-go-back-to-yn') }}
          </div>
        </PopupMessage>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.TOO_MANY_ACTIONS === activeModal">
      <BasePopup
        title="Too many attempts"
        @close="closePopup"
        withClose="true"
      >
        <PopupMessage
          buttonClass="button button--stroke"
          :buttonTitle="$t('basepopup-close-btn-title')"
          @popupMessageClickOnButton="closePopup"
        >
          <template v-slot:icon>
            <span class="popup-many-attempts__ico-wrapper">
              <svg-icon class="popup-message__ico email-error" name="email-error"></svg-icon>
            </span>
          </template>
          <div class="popup-many-attempts__text">
            <div class="popup-many-attempts__text-item">
              {{ $t('basepopup-many-attempts-registration') }}
            </div>
          </div>
        </PopupMessage>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.LOGIN_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-login-title')"
        @close="closeAndClearCallback"
        withClose="true"
      >
        <FormLogin/>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.GUEST_LOGIN === activeModal">
      <BasePopup
        :title="$t('basepopup-guest-login-title')"
        @close="closeAndClearCallback"
        withClose="true"
        shouldOverflow="true"
      >
        <GuestLogin />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.PASSWORD_WAS_SENT === activeModal">
      <BasePopup
        :title="$t('basepopup-check-email-msg-title')"
        @close="closeGuestRegistrationPopup()"
        withClose="true"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-check-email-msg-title')"
          :icoName="$t('basepopup-reset-pw-send')"
          buttonClass="button button--stroke"
          @popupMessageClickOnButton="closeGuestRegistrationPopup()"
        >
          <div class="popup__text">System generated password was sent to your email. Please login using that password.</div>
        </PopupMessage>
      </BasePopup>
    </template>

    <template v-if="showResetPasswordPopup">
      <BasePopup
        :title="$t('basepopup-reset-pw-title')"
        @close="closePopup"
        withClose="true"
      >
        <ResetPassword/>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.SHOPPING_CART_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-shopping-cart-title')"
        :productCount="productsInCartCount"
        @close="closePopup"
        class="popup--big"
      >
        <ShoppingCart/>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.EMPTY_SHOPPING_CART_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-shopping-cart-title')"
        @close="closePopup"
        class="popup--big"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-empty-cart')"
          :messageText="$t('basepopup-need-and-come-back-title')"
          :icoName="$t('basepopup-cart-stroke')"
          buttonClass="button"
          :buttonTitle="$t('basepopup-cont-shop-btn-text')"
          class="popup-message--cart"
          @popupMessageClickOnButton="closePopup"
        />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.LOGIN_TIME_OUT_ON_SITE === activeModal">
      <BasePopup
        :title="$t('basepopup-system-alert-title')"
        @close="closePopup"
        class="popup--big"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-logged-out')"
          :messageText="$t('basepopup-back-and-shopping')"
          :icoName="$t('basepopup-cart-stroke')"
          buttonClass="button"
          :buttonTitle="$t('basepopup-login-title')"
          class="popup-message--cart"
          @popupMessageClickOnButton="setActiveModal(POPUP_NAMES.LOGIN_POPUP)"
        />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.LOGIN_TIME_OUT_IN_CART === activeModal">
      <BasePopup
        :title="$t('basepopup-shopping-cart-title')"
        @close="closePopup"
        class="popup--big"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-logged-out')"
          :messageText="$t('basepopup-login-again')"
          :icoName="$t('basepopup-cart-stroke')"
          buttonClass="button"
          :buttonTitle="$t('basepopup-login-title')"
          class="popup-message--cart"
          @popupMessageClickOnButton="setActiveModal(POPUP_NAMES.LOGIN_POPUP)"
        />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.EMAIL_ALREADY_USED === activeModal">
      <BasePopup
        title="Error"
        @close="closePopup"
        class="popup--cart-empty"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-existing-email')"
          :messageText="$t('basepopup-select-another-email')"
          :icoName="$t('basepopup-cart-stroke')"
          buttonClass="button"
          :buttonTitle="$t('basepopup-close-btn-title')"
          class="popup-message--cart"
          @popupMessageClickOnButton="unsetComponent"
        />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.DATA_SUCCESSFULLY_UPDATED === activeModal">
      <BasePopup
        title="Success"
        @close="closePopup"
        class="popup--cart-empty"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-updated-profile')"
          :messageText="$t('basepopup-continue-to-use-site')"
          :icoName="$t('basepopup-profile-info')"
          buttonClass="button"
          :buttonTitle="$t('basepopup-close-btn-title')"
          class="popup-message--cart"
          @popupMessageClickOnButton="profilePageRedirect"
        />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.QUOTATION_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-quotation-req')"
        @close="closePopup"
        @clickOnInfoIco="scrollToInfoBlock"
        class="popup--big"
        withInfoButton="true"
      >
        <ProductQuotation/>
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.QUOTATION_SUCCESS_POPUP === activeModal">
      <BasePopup
        :title="$t('basepopup-quotation-req')"
        @close="closePopup"
      >
        <PopupMessage
          :messageTitle="$t('basepopup-quote-successfully-sent')"
          :messageText="successQuotationMessageText"
          :icoName="$t('basepopup-reset-pw-send')"
          buttonClass="button"
          :buttonTitle="$t('basepopup-thank-you')"
          @popupMessageClickOnButton="unsetComponent"
        />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.TOO_LARGE_ORDER === activeModal">
      <BasePopup
        :showTitle="false"
      >
        <LargeOrder
          @close="closeLargeOrderPopup"
        />
      </BasePopup>
    </template>
    <template v-if="POPUP_NAMES.SAVED_ADDRESS === activeModal">
      <BasePopup
        :title="$t('saved-addresses')"
        @close="closePopup"
        :withClose="true"
      >
        <SavedAddresses />
      </BasePopup>
    </template>

    <template v-if="POPUP_NAMES.ADD_NEW_ADDRESS === activeModal">
      <BasePopup
        :title="$t('add-new-address')"
        @close="closeAddNewAddressPopup"
        :withClose="true"
        :add_address_popup="true"
        :is_Address_popup="true"
      >
        <AddNewAddress />
      </BasePopup>
    </template>

      <BaseFooterExtended />
  </div>
</template>

<script>
  import BaseLoadingScreen from '@/components/base/BaseLoadingScreen';

  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
  import { POPUP_NAMES } from '@/utils/popup-names';
  import { ERROR_TYPES } from '@/utils/types/error';
  import debounce from 'lodash/debounce';

  export default {
    components: {
      RegistrationForm:() => import('@/components/registration/RegistrationForm'),
      ProductQuotation:() => import('@/components/product/ProductQuotation'),
      BaseHeader:() => import('@/components/base/header/BaseHeader.vue'),
      BaseSearchMobile:() => import('@/components/search/BaseSearchMobile.vue'),
      BasePopup:() => import('@/components/popup/BasePopup'),
      FormLogin:() => import('@/components/login/FormLogin'),
      ResetPassword:() => import('@/components/reset-password/ResetPassword'),
      ShoppingCart:() => import('@/components/shopping-cart/ShoppingCart'),
      PopupMessage:() => import('@/components/popup/PopupMessage'),
      BaseSidebar:() => import('@/components/base/sidebar/BaseSidebar'),
      BaseLoadingScreen,
      BaseFooterExtended:() => import('@/components/base/BaseFooterExtended'),
      LargeOrder: () => import('@/components/product/LargeOrder.vue'),
      GuestLogin: () => import('@/components/login/GuestLogin'),
      AddNewAddress: () => import('@/components/checkout/details/AddNewAddress'),
      SavedAddresses: () => import('@/components/checkout/details/SavedAddresses')
    },
    data() {
      return {
        POPUP_NAMES,
        isRibbonShown: true,
        Mobile: true
      };
    },
    computed: {
      ...mapGetters({
        productsInCartCount: 'cart/productsInCartCount'
      }),
      ...mapState({
        activeModal: state => state.modal.activeModal,
        registeredEmail: state => state.shared.registeredEmail,
        portData: state => state.port.portData,
        isShownChangeEmailButton: state => state.shared.isShownChangeEmailButton
      }),
      showResetPasswordPopup() {
        return [POPUP_NAMES.RESET_PASSWORD_POPUP_1, POPUP_NAMES.RESET_PASSWORD_POPUP_2,
          POPUP_NAMES.RESET_PASSWORD_POPUP_3, POPUP_NAMES.RESET_PASSWORD_POPUP_4].includes(this.activeModal);
      },
      successQuotationMessageText() {
        const email = this.$auth.loggedIn ? this.$auth.user.email : '';
        return 'Further information about the request will be shared with you via email <b>' + email  + '</b>';
      },
    },
    methods: {
      ...mapActions({
        loadPortData: 'port/loadPortData',
      }),
      ...mapMutations({
        setActiveModal: 'modal/setComponent',
        unsetComponent: 'modal/unsetComponent',
        clearCallbackAfterLogin: 'shared/clearCallbackAfterLogin'
      }),
      closePopup() {
        this.unsetComponent();
      },
      closeAndClearCallback() {
        this.closePopup();
        this.clearCallbackAfterLogin();
      },
      scrollToInfoBlock() {
        document.querySelector('[data-quotation-popup-how-it-works]').scrollIntoView();
      },
      changeEmail() {
        this.$axios.$get('email_actions/check').then(() => {
          this.setActiveModal(POPUP_NAMES.REGISTRATION_POPUP);
        }).catch(({ data }) => {
          this.$router.push({ path: '/' });
          if (data.type === ERROR_TYPES.MANY_EMAIL_ACTIONS) {
            this.setActiveModal(POPUP_NAMES.TOO_MANY_ACTIONS);
          }
        });
      },
      getRibbonStatus() {
        this.isRibbonShown = window.sessionStorage.getItem('ribbon') === null;
      },
      closeRibbon() {
        this.isRibbonShown = false;
        window.sessionStorage.setItem('ribbon', 'false');
      },
      checkMobile(){
        if(window?.innerWidth < 615) this.Mobile = true;
        else this.Mobile = false;
      },
      profilePageRedirect(){
        this.unsetComponent();
        this.$router.push({name: 'index'});
      },
      closeLargeOrderPopup(){
        this.setActiveModal(POPUP_NAMES.SHOPPING_CART_POPUP);
      },
      closeGuestRegistrationPopup(){
        // this.closePopup();
        this.setActiveModal(POPUP_NAMES.LOGIN_POPUP);
      },
      closeAddNewAddressPopup(){
        this.setActiveModal(POPUP_NAMES.SAVED_ADDRESS);
      }
    },
    mounted() {
      this.getRibbonStatus();
      this.checkMobile();
      window.addEventListener('resize', debounce(this.checkMobile, 200));
    },
    created() {
      if (this.$route.params.port_slug && !this.portData) {
        this.loadPortData({ slug: this.$route.params.port_slug });
      }
    }
  };
</script>

<style>
  .popup-many-attempts__text {
    color: var(--secondary-color-dark);
    text-align: center;
    font-size: 1rem;
    box-sizing: border-box;
    line-height: 140%;
  }

  .popup-many-attempts__text-item {
    margin-top: 24px;
  }

  .popup__text {
    color: var(--secondary-color-dark);
    text-align: center;
    font-size: 1rem;
    box-sizing: border-box;
    line-height: 140%;

    @media (--viewport-mobile) {
      padding: 0 32px;
    }
  }

  .popup-many-attempts__ico-wrapper {
    display: block;
    position: relative;
    margin: 0 auto 24px;
    width: 88px;
    height: 88px;
    flex-shrink: 0;
  }
  .crew-party-links{
    color: var(--brand-color);
  }

  .crew-party-text{
    font-weight: 600;
    font-size: 1.1rem;
  }
</style>